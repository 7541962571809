.jupyter {
  @import "notebook";

  @extend .mb-5;

  pre {
    font-size: 10pt;
  }

  code {
    font-size: 0.9em;
  }

  @include media-breakpoint-up(sm) {
    .cell {
      padding-left: 0;
      padding-right: 0;
    }
    .prompt {
      position: absolute;
      top: 0;
      right: 100%;
      height: 100%;
    }
    div.output_subarea {
      max-width: 100%;
    }
    .input,
    .output {
      position: relative;
    }
    .input_prompt {
      color: $accentBlue;
    }
    .output_prompt {
      color: $accentRed;
    }
    .text_cell_render {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
