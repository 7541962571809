.copy {
  cursor: pointer;
  .success {
    display: none;
  }
}

.copy.copied {
  .action {
    display: none;
  }
  .success {
    display: inline;
  }
}
