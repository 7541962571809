@font-face {
  font-family: Slack-Lato;
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/lato-regular.woff2") format("woff2"), url("/assets/fonts/lato-regular.woff") format("woff");
  unicode-range: U+0000-f8fe, U+f900-ffff;
}

@font-face {
  font-family: "TeutonNormal";
  src: url("/assets/fonts/teutonnormal-webfont-hinting.eot");
  src: url("/assets/fonts/teutonnormal-webfont-hinting.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/teutonnormal-webfont-hinting.woff") format("woff"),
    url("/assets/fonts/teutonnormal-webfont-hinting.ttf") format("truetype"),
    url("/assets/fonts/teutonnormal-webfont-hinting.svg#TeutonNormal") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TeutonNormal";
  src: url("/assets/fonts/teutonnormalbold-webfont-hinting.eot");
  src: url("/assets/fonts/teutonnormalbold-webfont-hinting.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/teutonnormalbold-webfont-hinting.woff") format("woff"),
    url("/assets/fonts/teutonnormalbold-webfont-hinting.ttf") format("truetype"),
    url("/assets/fonts/teutonnormalbold-webfont-hinting.svg#TeutonNormalNormalBold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@import url('https://rsms.me/inter/inter.css');
