@import "variables";

.frontpage {
  // @extend .bg-light;

  .hero {
    color: $white;
    background-image: url("/assets/images/mf.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $blue;
    position: relative;

    h1 {
      font-size: calc(30px + 2.2vw);
      text-transform: uppercase;
      text-shadow: $shadow;
      font-weight: bold;
    }
  }

  .hero > * {
    position: relative;
  }

  .hero::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .ribbon {
    // background-color: $white;
    // box-shadow: $shadow;
    position: relative;

    a {
      color: inherit;
    }
    a:hover {
      text-decoration: none;
    }

    .stats {
      text-align: center;
      font-size: calc(10px + 0.3vw);
      color: $darkGrey;
      background-color: rgba(255, 255, 255, 0.7);
      // border-radius: 5px;

      .number {
        @include headingFont;
        font-size: calc(12px + 1.5vw);
        vertical-align: middle;
      }

      a:hover .number {
        color: $blue;
      }
    }
  }

  .hot {
    .btn {
      h3 {
        margin-bottom: -3px;
      }
    }
    .button:hover {
      background-color: $lightBlue;
      transition: background-color 200ms;
    }
  }

  .stories {
    .post {
      @extend .bg-light;
      display: block;
      text-decoration: none;
      color: inherit;
      transition: background-color 200ms;
    }
  }

  .news {
    border-left: 1px solid #eee;
  }

  .topics {
    .topic {
      @extend .bg-light;
      display: block;
      text-decoration: none;
      color: inherit;
      transition: background-color 200ms;

      h3 {
        border-bottom: 1px solid $white;
      }
      p {
        text-align: left;
      }
    }
  }
}
