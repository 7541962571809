.search-results {
  #resultsTabs {
    li.noresults a {
      @extend .text-muted;
    }
  }

  .result {
    @extend .mt-3;

    em {
      font-style: normal;
      background-color: $accentGreen;
      color: $white;
    }
    p {
      @extend .small;
      @extend .text-muted;
      @extend .text-left;
    }
  }
}
