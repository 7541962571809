$blue: #093d93;
$lightBlue: #009feb;
$white: #fff;
$black: #000;
$grey: #777;
$darkGrey: #333;
$lightGrey: #f9f9f9;

$link-color: #093d93;

$accentBlue: #0087c8;
$accentGreen: #00a5a5;
$accentRed: #e12323;
$accentPurple: #7d5d9f;

$primary: $accentBlue;
$secondary: $accentGreen;

$shadow: 0 0 8px rgba(0, 0, 0, 0.5);
$shadow-light: 0 0 5px rgba(0, 0, 0, 0.1);

$borderRadius: 2px;

// $baseFont: Arial, Helvetica, sans-serif;
@mixin baseFont {
  // font-family: Slack-Lato, "Helvetica Neue", Helvetica, sans-serif;
  font-family: "Inter", sans-serif;
  font-weight: 350;
  @supports (font-variation-settings: normal) {
    font-family: "Inter var", sans-serif;
  }
}

@mixin headingFont {
  font-family: TeutonNormal, Arial, Helvetica, sans-serif;
}
