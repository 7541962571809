@import "variables";

.search {
  background-color: $white;
  height: 50px;
  border-radius: 25px;
  padding: 0 0 0 15px;
  color: #000;
  position: relative;
  line-height: 50px;

  .input {
    input {
      border: none;
      outline: none;
      width: 100%;
      padding: 0;
    }
    position: absolute;
    top: 0;
    left: 25px;
    right: 48px;
    bottom: 0;
    height: 50px;
  }
  button {
    position: absolute;
    top: 1px;
    right: 1px;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 12px;
    border-radius: 24px;

    img {
      display: block;
    }
  }
  button:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
