.stories {
  .info {
    .item:not(:last-child)::after {
      content: " | ";
    }
  }
}
.story {
  .info {
    // .item:not(:last-child){
    //   @extend .mr-4;
    // }
    .item:not(:last-child)::after {
      content: ",";
    }
  }
}
