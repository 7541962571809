.md-content {
  img {
    max-width: 100%;
  }
  h2 {
    @extend .mt-5;
  }
  h2:first-child {
    @extend .mt-0;
  }

  h3 {
    @extend .mt-4;
  }
  h3:first-child {
    @extend .mt-0;
  }

  .footnotes {
    @extend .mt-5;
    padding-top: 10px;
    border-top: 1px solid $black;
    font-size: 0.85em;
    p{
      @extend .mb-1;
    }
  }
}
