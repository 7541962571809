@import "variables";

.script {
  border-radius: 10px;
  overflow: hidden;
  font-size: 0.9em;
  position: relative;

  .copy {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #0f140f;
    color: $white !important;
  }

  pre {
    padding: 2em;
    margin: 0;
  }
}
