@import "variables.scss";

main {
  // padding-top: 107px;
  // padding-bottom: 32px;
  min-height: 100%;
}

.page {
  padding-bottom: 20vh;

  @extend .mx-xl-5;
  @extend .px-xl-5;
}

.hero {
  color: $black;
  background: rgb(238, 238, 238);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.08) 0%,
    rgba(0, 0, 0, 0.1) 10%,
    rgba(0, 0, 0, 0.1) 41%,
    #f8f9fa 80%,
    #f8f9fa 100%
  );

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  h1 {
    font-size: calc(15px + 1.1vw);
    // text-transform: uppercase;
    // text-shadow: $shadow;
    font-weight: bold;
    margin: 0;
  }
}

header {
  // position: fixed;
  // left: 0;
  // top: 0;
  // right: 0;

  padding: 10px 0;

  background-color: $white;
  z-index: 1000;
  // box-shadow: $shadow;

  .navbar {
    padding-left: 0;
    padding-right: 0;
  }
  
  .navbar-brand {
    a:hover {
      text-decoration: none;
    }
  }

  .navbar-nav {
    flex-grow: 1;
  }

  .search {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    .search {
      width: 300px;
    }
  }

  min-height: 60px;
}

footer {
  // position: fixed;
  // left: 0;
  // bottom: 0;
  // right: 0;
  // height: 32px;

  background-color: $lightGrey;
  color: $darkGrey;

  a {
    color: inherit;
  }
  a:hover {
    color: inherit;
  }
  p {
    margin: 0;
  }

  .twitter {
    img {
      height: 24px;
      vertical-align: baseline;
    }
  }
}

.sidebar {
  .title {
    @extend h1;
    background-color: rgba(255, 255, 255, 0.1);
  }

  h3,
  h4,
  h5,
  h6 {
    @extend .ml-4;
  }

  nav {
    a {
      @extend .pl-4;
      @extend .py-2;
      display: block;
      color: #666;
      text-decoration: none;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    a:last-child {
      border-bottom: none;
    }

    a:hover,
    a:active {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
