.simple-card {
  background-color: $light;
  
  display: block;
  color: $black;
  border-radius: 2px;
  padding: 20px;
  p {
    opacity: 0.8;
    text-align: left;
  }
  .actions {
    a {
      @extend .mr-1;
      @extend .mb-1;
    }
  }
}
