@import "variables";

html,
body {
  height: 100%;
  @include baseFont;
  font-size: 15px;
}

p {
  text-align: justify;
}

h1 {
  font-size: 2em;
  @include headingFont;
}
h2 {
  font-size: 1.8em;
  @include headingFont;
}

h3 {
  font-size: 1.6em;
  @include headingFont;
}

h4 {
  font-size: 1.2em;
  font-weight: bold;
  @include headingFont;
}

h5 {
  font-size: 1em;
  font-weight: bold;
}

.btn {
  .material-icons {
    vertical-align: middle;
  }
}

.bg-white {
  background-color: $white;
}

.loading {
  animation: color-change 1s infinite;
  animation-timing-function: ease-in-out;
}

@keyframes color-change {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    color: 1;
  }
}
