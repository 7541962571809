@import "variables";

@import "bootstrap/bootstrap.scss";

/* Remove the top border when a table is missing the header */
.table > tbody > tr:first-child {
  > td,
  > th {
    border: none;
  }
}

/* Include the border when there's a header */
.table > thead + tbody > tr:first-child {
  > td,
  > th {
    border-top: 1px solid #ddd;
  }
}

.card,
.btn,
.form-control,
.badge:not(.badge-pill),
.nav-pills .nav-link {
  border-radius: $borderRadius;
}

.badge {
  @include baseFont;
  font-weight: bold;
}
